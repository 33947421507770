import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Collapse, ListItemIcon, Grid, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { AttachMoney } from '@mui/icons-material';
import FaucetIcon from '@mui/icons-material/LocalDrink';
import DrainIcon from '@mui/icons-material/CallToAction';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BathtubIcon from '@mui/icons-material/Bathtub';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ReportIcon2 from '@mui/icons-material/Assessment';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
//import reportIcon3 from '@mui/icons-material/Summarize';
import EmployeeIcon from '@mui/icons-material/SupervisedUserCircle';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EventIcon from '@mui/icons-material/Event';
import AdminIcon from '@mui/icons-material/SupervisedUserCircle';
import BuildersIcon from '@mui/icons-material/Group';
import BidsIcon from '@mui/icons-material/MonetizationOn';
import MaterialsIcon from '@mui/icons-material/Category';
import JobsIcon from '@mui/icons-material/Work';
import ReportIcon from '@mui/icons-material/Report';
import LotIcon from '@mui/icons-material/LocationOn';
import FolderIcon from '@mui/icons-material/Folder';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import NeighborhoodIcon from '@mui/icons-material/LocationCity';
import EditIcon from '@mui/icons-material/Edit';
import InsertIcon from '@mui/icons-material/Add';
import SlabIcon from '@mui/icons-material/Dns';
import LocationCityIcon from '@mui/icons-material/Business';
import LandscapeIcon from '@mui/icons-material/Landscape';
import SewerIcon from '@mui/icons-material/Opacity';
import logo from '../logo.png';
import PhaseEdit from './phase/phase';
import GenericEdit from './GenericEdit';
import GenericInsert from './GenericInsert';
import LocationOnIcon from '@mui/icons-material/LocationOn';
const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#B3CDE6',
    color: 'black',
  },
  drawer: {
    width: 240,
  },
  drawerPaper: {
    width: 240,
  },
  content: {
    flexGrow: 1,
    marginLeft: 240,
    padding: 16,
  },
  nested: {
    paddingLeft: '25px !important', // Direct padding with !important
  },
  nested2: {
    paddingLeft: '50px !important', // Further indented level with !important
  },
  sideMenu: {
    backgroundColor: '#E5F0F9',
    zIndex: -1,
    marginTop: 65,
    paddingBottom: 65,
  },
});
const SideMenu = ({ sideMenuOpen, setSideMenuOpen }) => {
  const classes = useStyles();
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [purchasingOpen, setpurchasingOpen] = React.useState(false);
  const [scheduleOpen, setScheduleOpen] = React.useState(false);
  const [phaseOpen, setPhaseOpen] = React.useState(false);
  const [reportsOpen, setReportsOpen] = React.useState(false);
  const [SlabOpen, setSlabOpen] = React.useState(false);
  const [RoughOpen, setRoughOpen] = React.useState(false);
  const [SetoutOpen, setSetoutOpen] = React.useState(false);
  const [jobsOpen, setJobsOpen] = React.useState(false);
  const [bidHover, setBidHover] = React.useState(false);
  const navigate = useNavigate();
  const access_level = localStorage.getItem('access');
  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleClickAdmin = () => {
    setAdminOpen(!adminOpen);
  };
  const handleClickSchedule = () => {
    setScheduleOpen(!scheduleOpen);
  }
  const handleClickPhase = () => {
    setPhaseOpen(!phaseOpen);
  }
  const handleClickReports = () => {
    setReportsOpen(!reportsOpen);
  }
  const handleClickSlab = () => {
    setSlabOpen(!SlabOpen);
  }
  const handleClickRough = () => {
    setRoughOpen(!RoughOpen);
  }
  const handleClickSetout = () => {
    setSetoutOpen(!SetoutOpen);
  }
  // const handleClickJobs = () => {
  //   setJobsOpen(!jobsOpen);
  // };
  const handleMouseEnter = () => {
    setBidHover(true);
  };
  const handleMouseLeave = () => {
    setBidHover(false);
  };
  return (
    <Drawer
      variant="persistent"
      open={sideMenuOpen}
      classes={{ paper: `${classes.drawerPaper} ${classes.sideMenu}`, }} >
      <List>
        <ListItem button onClick={() => handleNavigation('/phase/lot')}>
          <ListItemIcon>
            <LotIcon />
          </ListItemIcon>
          <ListItemText primary="New Lot" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation('/wip')}>
          <ListItemIcon>
            <BuildersIcon />
          </ListItemIcon>
          <ListItemText primary="Work In Progress" />
        </ListItem>

        <ListItem button onClick={() => handleNavigation('/phase/po')}>
          <ListItemIcon>
            <BidsIcon />
          </ListItemIcon>
          <ListItemText primary="PO" />
        </ListItem>

        {/* <ListItem button onClick={() => handleNavigation('/phase/warranty')}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Warranty" />
        </ListItem> */}


        <ListItem button onClick={() => handleNavigation('/phase')}>
          <ListItemIcon>
            <BathtubIcon />
          </ListItemIcon>
          <ListItemText primary="Phase" />
        </ListItem>

        <ListItem button onClick={() => handleNavigation('/phase/permit')}>
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          <ListItemText primary="Permit" />
        </ListItem>
        {/* <Collapse in={phaseOpen} timeout="auto" unmountOnExit>
          <List component="div"> */}



        {/* <ListItem button className={classes.nested} onClick={() => handleNavigation('/phase/slab_inspection')}>
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inspections" />
                </ListItem> */}

        {/* <ListItem button className={classes.nested} onClick={handleClickSlab}>
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="Slab" />
              {SlabOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={SlabOpen} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/slab')}>
                  <ListItemIcon>
                    <SlabIcon />
                  </ListItemIcon>
                  <ListItemText primary="Slab" />
                </ListItem>

                <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/slab_qc')}>
                  <ListItemIcon>
                    <CheckCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Quality Check" />
                </ListItem> */}

        {/* <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/slab_inspection')}>
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="Slab Inspection" />
                </ListItem> */}

        {/* <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/underground')}>
                  <ListItemIcon>
                    <SewerIcon />
                  </ListItemIcon>
                  <ListItemText primary="Underground" />
                </ListItem> */}

        {/* <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/underground_inspection')}>
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="Underground Inspection" />
                </ListItem> */}

        {/* </List>
            </Collapse> */}

        {/* <ListItem button className={classes.nested} onClick={handleClickRough}>
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="Rough" />
              {RoughOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem> */}
        {/* <Collapse in={RoughOpen} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/rough')}>
                  <ListItemIcon>
                    <MaterialsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Rough" />
                </ListItem>

                <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/rough_qc')}>
                  <ListItemIcon>
                    <CheckCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Quality Check" />
                </ListItem> */}


        {/* <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/rough_inspection')}>
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="Rough Inspection" />
                </ListItem> */}

        {/* <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/draintubs')}>
                  <ListItemIcon>
                    <BathtubIcon />
                  </ListItemIcon>
                  <ListItemText primary="Drain Tubs" />
                </ListItem>
              </List>
            </Collapse> */}
        {/* <ListItem button className={classes.nested} onClick={handleClickSetout}>
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="Setout" />
              {SetoutOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem> */}
        {/* <Collapse in={SetoutOpen} timeout="auto" unmountOnExit>
              <List component="div">

                <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/setout')}>
                  <ListItemIcon>
                    <SewerIcon />
                  </ListItemIcon>
                  <ListItemText primary="Setout" />
                </ListItem>

                <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/setout_qc')}>
                  <ListItemIcon>
                    <CheckCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Quality Check" />
                </ListItem>

                
                {/* <ListItem button className={classes.nested2} onClick={() => handleNavigation('/phase/setout_inspection')}>
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="Setout Inspection" />
                </ListItem> 

              </List>
            </Collapse> */}
        {/* </List>
          
        </Collapse> */}

        {/* <ListItem button onClick={handleClickSchedule}>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="Schedule" />
          {scheduleOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={scheduleOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/dailySchedule')}>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText primary="Daily" />
            </ListItem>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/employeeSchedule')}>
              <ListItemIcon>
                <EmployeeIcon />
              </ListItemIcon>
              <ListItemText primary="Employee" />
            </ListItem>
          </List>
        </Collapse> */}

        <ListItem button onClick={() => handleNavigation('/schedule')}>
          <ListItemIcon>
            <DateRangeIcon />
          </ListItemIcon>
          <ListItemText primary="Schedule" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation('/piece_work')}>
          <ListItemIcon>
            <FaucetIcon />
          </ListItemIcon>
          <ListItemText primary="Piece Work" />
        </ListItem>

        <ListItem button onClick={() => setpurchasingOpen(!purchasingOpen)}>
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText primary="Purchasing" />
          {purchasingOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={purchasingOpen} timeout="auto" unmountOnExit>
        
        <ListItem button className={classes.nested} onClick={() => handleNavigation('/purchasing')}>
            <ListItemIcon>
              <BathtubIcon />
            </ListItemIcon>
            <ListItemText primary="Purchasing" />
          </ListItem>

          <ListItem button className={classes.nested}  onClick={() => handleNavigation('/material_template')}>
            <ListItemIcon>
              <LotIcon />
            </ListItemIcon>
            <ListItemText primary="Material Templates" />
          </ListItem>

          <ListItem button  className={classes.nested} onClick={() => handleNavigation('/material_model')}>
            <ListItemIcon>
              <MaterialsIcon />
            </ListItemIcon>
            <ListItemText primary="Material List" />
          </ListItem>
        </Collapse>

        {access_level === 'full' && (
          <>
            <ListItem button onClick={handleClickAdmin}>
              <ListItemIcon>
                <AdminIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
              {adminOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={adminOpen} timeout="auto" unmountOnExit>
              <List component="div" >

                <ListItem button className={classes.nested} onClick={() => handleNavigation('/tasklist')}>
                  <ListItemIcon>
                    <WorkOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Work Items" />
                </ListItem>

                <ListItem button className={classes.nested} onClick={() => handleNavigation('/export')}>
                  <ListItemIcon>
                    <DownloadIcon />
                  </ListItemIcon>
                  <ListItemText primary="System Export" />
                </ListItem>

                <ListItem button className={classes.nested} onClick={() => handleNavigation('/neighborhood')}>
                  <ListItemIcon>
                    <NeighborhoodIcon />
                  </ListItemIcon>
                  <ListItemText primary="Neighborhoods" />
                </ListItem>

                <ListItem button className={classes.nested} onClick={() => handleNavigation('/material_templates')}>
                  <ListItemIcon>
                    <JobsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Material Template" />
                </ListItem>

                <ListItem button className={classes.nested} onClick={() => handleNavigation('/manufacturer')}>
                  <ListItemIcon>
                    <NeighborhoodIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manufacturers" />
                </ListItem>
                {/* <ListItem button className={classes.nested} onClick={() => handleNavigation('/bid')}>
              <ListItemIcon>
                <BidsIcon />
              </ListItemIcon>
              <ListItemText primary="Bids" />
            </ListItem> */}
                <ListItem button className={classes.nested} onClick={() => handleNavigation('/district')}>
                  <ListItemIcon>
                    <LocationCityIcon />
                  </ListItemIcon>
                  <ListItemText primary="District" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => handleNavigation('/municipality')}>
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="Municipality" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => handleNavigation('/builder')}>
                  <ListItemIcon>
                    <BuildersIcon />
                  </ListItemIcon>
                  <ListItemText primary="Builders" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => handleNavigation('/plan_number')}>
                  <ListItemIcon>
                    <NoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Plan Numbers" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => handleNavigation('/employee')}>
                  <ListItemIcon>
                    <EmployeeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Employees" />
                </ListItem>

                <ListItem button className={classes.nested} onClick={() => handleNavigation('/material_room')}>
                  <ListItemIcon>
                    <NeighborhoodIcon />
                  </ListItemIcon>
                  <ListItemText primary="Rooms" />
                </ListItem>

                <ListItem button className={classes.nested} onClick={() => handleNavigation('/employeePhase')}>
                  <ListItemIcon>
                    <EmployeeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Employee Phases" />
                </ListItem>

              </List>
            </Collapse>
          </>
        )}

        <ListItem button onClick={handleClickReports}>
          <ListItemIcon>
            <ReportIcon2 />
          </ListItemIcon>
          <ListItemText primary="Reports" />
          {reportsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/charts')}>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary="Overview" />
            </ListItem>
          </List>
        </Collapse>
      </List>

    </Drawer>
  );
};
export default SideMenu;